@use "../../../styles/color";
@use "../../../styles/text";

.user_picture {
  margin-right: 8px;
}

.oneRow {
  @include text.ellipsisOneRow();
}

.userSummary {
  display: flex;
  align-items: center;
  height: 43px; // to keep height the same with/without company/username
}
