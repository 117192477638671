@use "src/styles/color";
@use "../../../../variables";


// Grey = incoming message from left side
.cloud_wrap_gray {
  margin-right: 8px + variables.$message-list--padding-x--md;
  padding-left: 16px;
}

// Violet = outgoing message from right side
.cloud_wrap_violet {
  margin-left: 8px + variables.$message-list--padding-x--md;
}

.cloud_bottom {
  -webkit-user-select: text;
  user-select: text;
}

.cloud_bottom.cloud_bottom_personal {
  flex-direction: column;
  gap: 4px;
}

.name_message_time {
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-wrap: nowrap;

  justify-content: flex-end;
  align-items: center;
}

.check_and_text {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.message_text_wrap {
  white-space: pre-wrap;
}

.cloud {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 8px 12px;
  border-radius: 8px;
  width: fit-content;

  &.hasCorner {

    // Corner
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: (variables.$corner--height + variables.$corner--offset) 0 0 0;
    }
  }

  // Violet = outgoing message from right side
  &_violet {
    background: color.$violet100;
    margin-left: auto;
    margin-right: variables.$corner--width;
  }

  &_violet.hasCorner {
    border-bottom-right-radius: 0;

    // Corner
    &::before {
      right: -(variables.$corner--width);
      border-left: (variables.$corner--width + variables.$corner--offset) solid color.$violet100;
    }
  }


  // Grey = incoming message from left side
  &_gray {
    background: color.$grayscale100;
    margin-right: auto;
    margin-left: variables.$corner--width;
  }
  @at-root .cloud_wrap__is_group .cloud_gray:not(.cloud_with_avatar) {
    margin-left: variables.$avatar--size + variables.$corner--width;
  }
  &_gray.hasCorner {
    border-bottom-left-radius: 0;

    // Corner
    &::before {
      left: -(variables.$corner--width);
      border-right: (variables.$corner--width + variables.$corner--offset) solid color.$grayscale100;
    }
  }


  &_with_avatar {
    margin-left: 28px;
  }
}


.cloud_wrap__is_person_gray {
  margin-left: 12px;
}

.cloud_wrap__is_person_violet {
  margin-right: 12px;
}

.cloud_avatar {
  position: absolute;
  bottom: 0;
  right: -(variables.$avatar--size + variables.$corner--width);
  width: variables.$avatar--size;
  height: variables.$avatar--size;
}

.cloud_avatar_gray {
  left: -(variables.$avatar--size + variables.$corner--width);
}
