@use "../../../styles/media";
@use "../../../styles/spacing";

.inputGroupTitle {
  display: flex;
  gap: 4px;

  &_label {
    display: flex;
    @include media.dhd {
      flex-direction: column;
    }
  }
  &_tooltip {
    width: 248px;
  }
}

.sectionTitle {
  text-align: start;
}

.block {
  display: flex;

  margin-top: 24px;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:first-of-type {
    margin-top: 0;
  }
  gap: 16px;
  @include media.ds {
    gap: 20px;
  }
  .inputColumn {
    flex-grow: 1;
  }

  &_create {
    .titleColumn {
      @include media.ds {
        min-width: 202px;
        max-width: 202px;
      }
      @include media.dhd {
        min-width: 156px;
        max-width: 156px;
      }
    }
    flex-flow: column;
    @include media.ds {
      flex-flow: row;
      gap: 0;
    }
  }
  &_edit {
    .titleColumn {
      @include media.ds {
        min-width: 158px;
        max-width: 158px;
      }
      @include media.dhd {
        min-width: 182px;
        max-width: 182px;
      }
    }
  }
  flex-flow: column;
  @include media.dhd {
    flex-flow: row;
    gap: 0;
  }
}
.title_subtitle_group {
  display: flex;
  flex-flow: row;
  gap: spacing.$XXXXS;
  @include media.d {
    flex-flow: column;
    gap: 8px;
  }
}


