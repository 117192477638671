@use "src/styles/spacing";

$followListModalWidth: 448px;
$followListModalMaxHeight: 453px;
$followListModalTitleMarginBottom: 12px; // 20 (design) - 8 (default bottom margin for title)
$gapBetweenListItems: 12px;

$followListModalMaxHeight_mobile: 375px;
$followListModalTitleMarginBottom_mobile: 8px; // 16 (design) - 8 (default bottom margin for title)
$gapBetweenListItems_mobile: 16px;

$unfollowXsButtonWidthPlusGap: 87px;

.followListModal.followListModal {
  width: $followListModalWidth;
  max-height: $followListModalMaxHeight;

  display: flex;
  flex-direction: column;

  &_mobile {
    width: unset; // unset custom width for desktop to use default width for mobile (100%)
    max-height: $followListModalMaxHeight_mobile;
    .followList {
      gap: $gapBetweenListItems_mobile;;
      margin-top: $followListModalTitleMarginBottom_mobile;
    }
  }

  .followListWithScroll {
    overflow-y: scroll;
  }

  .followList {
    display: flex;
    flex-direction: column;
    gap: $gapBetweenListItems;

    margin-top: $followListModalTitleMarginBottom;
    height: 100%;
    padding-right: spacing.$XXXS;

    .followListItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;

      .userSummary {
        word-break: break-all; // to use ellipsis in 1-line <p> that doesn't fit its container
        max-width: calc(100% - $unfollowXsButtonWidthPlusGap); // text shouldn't change width after unfollow

        // TODO kit: design size is smaller than xs text size
        .company {
          line-height: 15px;
          font-size: 10px;
        }
      }
    }
  }

  .spinnerWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
