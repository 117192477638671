@mixin ellipsisOneRow {
  @include ellipsisCommonStyles;
  -webkit-line-clamp: 1;
}
@mixin ellipsisTwoRows {
  @include ellipsisCommonStyles;
  -webkit-line-clamp: 2;
}
@mixin ellipsis3Rows {
  @include ellipsisCommonStyles;
  -webkit-line-clamp: 3;
}
@mixin ellipsis4Rows {
  @include ellipsisCommonStyles;
  -webkit-line-clamp: 4;
}
@mixin ellipsisAtRow($nRow) {
  @include ellipsisCommonStyles;
  -webkit-line-clamp: $nRow;
}

@mixin ellipsisCommonStyles {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}


@mixin noTextSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin ignoreLink {
  a:link { text-decoration: none; }
  a:visited { text-decoration: none; }
  a:hover { text-decoration: none; }
  a:active { text-decoration: none; }
}

@mixin longWordBreak {
  word-break: break-word;
  word-wrap: break-word;
}