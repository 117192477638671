@use "src/styles/common.module";

.wrap {
  @extend .noSelect;
  display: inline-block;
  width: 100%; // WTF?
  a {
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    ::active {
      text-decoration: none;
    }
  }
}

.flex {
  display: flex;
}

.transparentButton {
  @extend .noSelect;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  cursor: pointer;
}
