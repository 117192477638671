@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";
@use "src/styles/common.module";

//$gapBetweenMenuItems: 4px;

.wrap {
  @extend .noSelect;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: spacing.$XXXS;
  right: 0;
  //gap: $gapBetweenMenuItems;
  width: 170px;
  position: absolute;
  // top: 40px;
  top: 0;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow:
    0 0 0.5px rgba(0, 0, 0, 0.35),
    0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  z-index: 5;
  word-break: break-word;
  white-space: normal;
  @include media.tp {
    top: 0;
    right: 0;
    bottom: unset;
  }

  .menuItem {
    display: flex;
    justify-content: flex-start;
    padding: spacing.$XXXXS;
    width: 100%;
    height: 100%;
    text-align: left;
  }

  // TODO: new component in ui-kit
  // https://evvve.atlassian.net/wiki/spaces/KB/pages/698548226/CmD.+Context+Menu
  //.button:hover {
  //  background-color: color.$grayscale100;
  //  border-radius: color.$grayscale100;
  //}
  //.button:active {
  //  background-color: color.$grayscale200;
  //  border-radius: color.$grayscale100;
  //}

  .spinner {
    margin: auto;
  }
}

.close {
  display: none;
}

.preventClickArea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0;
  z-index: 9999;
  user-select: none;
}


