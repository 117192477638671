@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/spacing";

.wrap {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.inner {
  display: flex;
  background: color.$white;
  padding: spacing.$XXXS spacing.$XS;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: color.$grayscale100;
  }
  &:active {
    background: color.$grayscale300;
  }
}

.avatar {
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-left: 12px;
}
