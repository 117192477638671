@use "src/styles/color";
@use "src/styles/media";
@use "src/styles/text";

.messageButton.messageButton {
  width: 100%;
}

.messagesRestricted {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  white-space: pre-wrap;
  width: 100%;
  margin: 0 auto;

  background-color: color.$white;
  border-radius: 12px;

  padding: 8px;

  @include text.longWordBreak;
}
