@use "src/styles/common.module";

.wrap {
  @extend .noSelect;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.tooltip.tooltip__visible{
  visibility: visible;
  display: block;
}
.wrap:hover .tooltip {
  visibility: visible;
  display: block;
}
.tooltip__visible {
  visibility: visible;
  display: block;
}

.tooltip {
  position: absolute;
  z-index: 4;
  right: 0;
  visibility: hidden;
  display: none;
}
.tooltip svg{
  z-index: 0;
}

.tooltip__leftM {
  right: 40px;
  top: -19px;
}
.tooltip__leftS {
  right: 32px;
  top: -23px;
}

.tooltip__rightM {
  top: -21px;
  left: 40px;
}
.tooltip__rightS {
  top: -25px;
  left: 32px;
}
.tooltip__topM {
  bottom: 48px;
  right: -36px;
}
.tooltip__topS {
  bottom: 48px;
  right: -28px;
}
.tooltip__bottomM {
  right: -36px;
  top: 40px;
}
.tooltip__bottomS {
  right: -40px;
  top: 32px;
}