*, :before, :after  {
  box-sizing: border-box;
}
.tooltip {
  position: relative;
  padding: 16px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px rgba(189, 189, 189, 0.5), 0px 1px 1px rgba(242, 242, 242, 0.5);
  width: 216px;
  text-align: start;
  height: 100%;
  word-break: break-word;
}
.left::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  right: 0;
  top: 32px;
  border: 8px solid transparent;
  border-right: 0;
  border-left: 8px solid #FFFFFF;
  transform: translate(calc(98%), -50%);
}
.top::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  right: 32px;
  bottom: 0;
  border: 8px solid transparent;
  border-bottom: 0;
  border-top: 8px solid #FFFFFF;
  transform: translate(-50%, calc(98%));
}

.right::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  left: 0;
  top: 32px;
  border: 8px solid transparent;
  border-right: 8px solid #FFFFFF;
  transform: translate(calc(-98%), -40%);
}

.bottom::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  right: 32px;
  top: 0px;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid #FFFFFF;
  transform: translate(-50%, calc(-98%));
}